<template>
  <NuxtPage />
  <!--<VueQueryDevtools />-->
</template>

<script setup lang="ts">
// import { VueQueryDevtools } from '@tanstack/vue-query-devtools';

const {
  public: { APP_NAME },
} = useRuntimeConfig();
const route = useRoute();

useHead({
  titleTemplate: route.path === '/' ? '%s' : '%s - ' + APP_NAME,
  bodyAttrs: {
    class: 'bg-black font-sans text-white antialiased',
  },
});
</script>
