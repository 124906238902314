import { default as index7nAaBLvC1KMeta } from "/home/forge/giopulse.cpi.dev/releases/20240531174710/src/pages/index.vue?macro=true";
import { default as indexPKixwhSMMUMeta } from "/home/forge/giopulse.cpi.dev/releases/20240531174710/src/pages/nodes/[nodeId]/index.vue?macro=true";
import { default as transfer51DfeuY8k7Meta } from "/home/forge/giopulse.cpi.dev/releases/20240531174710/src/pages/nodes/[nodeId]/transfer.vue?macro=true";
import { default as _91nodeId_93awLwPKLpoIMeta } from "/home/forge/giopulse.cpi.dev/releases/20240531174710/src/pages/nodes/[nodeId].vue?macro=true";
import { default as indexJlzgWf6vQkMeta } from "/home/forge/giopulse.cpi.dev/releases/20240531174710/src/pages/nodes/index.vue?macro=true";
export default [
  {
    name: index7nAaBLvC1KMeta?.name ?? "index",
    path: index7nAaBLvC1KMeta?.path ?? "/",
    meta: index7nAaBLvC1KMeta || {},
    alias: index7nAaBLvC1KMeta?.alias || [],
    redirect: index7nAaBLvC1KMeta?.redirect,
    component: () => import("/home/forge/giopulse.cpi.dev/releases/20240531174710/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91nodeId_93awLwPKLpoIMeta?.name ?? undefined,
    path: _91nodeId_93awLwPKLpoIMeta?.path ?? "/nodes/:nodeId()",
    meta: _91nodeId_93awLwPKLpoIMeta || {},
    alias: _91nodeId_93awLwPKLpoIMeta?.alias || [],
    redirect: _91nodeId_93awLwPKLpoIMeta?.redirect,
    component: () => import("/home/forge/giopulse.cpi.dev/releases/20240531174710/src/pages/nodes/[nodeId].vue").then(m => m.default || m),
    children: [
  {
    name: indexPKixwhSMMUMeta?.name ?? "nodes-nodeId",
    path: indexPKixwhSMMUMeta?.path ?? "",
    meta: indexPKixwhSMMUMeta || {},
    alias: indexPKixwhSMMUMeta?.alias || [],
    redirect: indexPKixwhSMMUMeta?.redirect,
    component: () => import("/home/forge/giopulse.cpi.dev/releases/20240531174710/src/pages/nodes/[nodeId]/index.vue").then(m => m.default || m)
  },
  {
    name: transfer51DfeuY8k7Meta?.name ?? "nodes-nodeId-transfer",
    path: transfer51DfeuY8k7Meta?.path ?? "transfer",
    meta: transfer51DfeuY8k7Meta || {},
    alias: transfer51DfeuY8k7Meta?.alias || [],
    redirect: transfer51DfeuY8k7Meta?.redirect,
    component: () => import("/home/forge/giopulse.cpi.dev/releases/20240531174710/src/pages/nodes/[nodeId]/transfer.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexJlzgWf6vQkMeta?.name ?? "nodes",
    path: indexJlzgWf6vQkMeta?.path ?? "/nodes",
    meta: indexJlzgWf6vQkMeta || {},
    alias: indexJlzgWf6vQkMeta?.alias || [],
    redirect: indexJlzgWf6vQkMeta?.redirect,
    component: () => import("/home/forge/giopulse.cpi.dev/releases/20240531174710/src/pages/nodes/index.vue").then(m => m.default || m)
  }
]